<template>
  <page-view :title="title" left-arrow>
    <van-form @submit="onSubmit">
      <van-field name="type" label="发票抬头类型">
        <template #input>
          <van-radio-group v-model="form.type" direction="horizontal">
            <van-radio name="1">个人</van-radio>
            <van-radio name="2">企业</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="form.type == '1'">
        <van-field name="name" label="姓名" placeholder="" v-model="form.name" />
        <van-field name="name" label="身份证号" placeholder="" v-model="form.name" />
        <van-field name="name" label="联系手机" placeholder="" v-model="form.name" />
        <van-field name="name" label="联系地址" placeholder="" v-model="form.name" />
        <van-field name="name" label="电子邮箱" placeholder="" v-model="form.name" />
      </div>
      <div v-else>
        <van-field name="name" label="发票抬头" placeholder="请填写抬头名称" v-model="form.name"
          :rules="[{ required: true, message: '请填写抬头名称'}]" />
        <van-field name="name" label="纳税标志" placeholder="" v-model="form.name" />
        <van-field name="name" label="纳税人识别号" placeholder="" v-model="form.name" />
        <van-field name="name" label="纳税人地址" placeholder="" v-model="form.name" />
        <van-field name="name" label="纳税人电话" placeholder="" v-model="form.name" />
        <van-field name="name" label="开户银行" placeholder="" v-model="form.name" />
        <van-field name="name" label="银行账号" placeholder="" v-model="form.name" />
        <van-field name="name" label="联系手机" placeholder="" v-model="form.name" />
        <van-field name="name" label="电子邮箱" placeholder="" v-model="form.name" />
      </div>

      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">保存</van-button>
      </div>
    </van-form>

    
  </page-view>
</template>

<script>
  import {
    Radio,
    RadioGroup
  } from 'vant';
  export default {
    name: 'InvoiceTitleEdit',
    components: {
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup
    },
    data() {
      return {
        title: '发票抬头维护',
        form: {
          type: '1'
        }
      }
    },
    methods: {
      onSubmit(values) {

      }
    },
  }
</script>